/* .swiper-card {
  height: 18rem;
}

@media (max-width: 768px) {
  .swiper-card {
    height: 12rem;
  }
} */

@media (max-width: 640px) {
  .swiper-card {
    height: 7rem;
  }
}
