@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.addtocart-sidebar::-webkit-scrollbar {
  display: none;
}
.order-details-container::-webkit-scrollbar {
  display: none;
}
.order-details-container::-webkit-scrollbar-thumb {
  height: 0.5rem;
  background-color: #232323;
  border-radius: 50px;
}
.order-details-container::-webkit-scrollbar {
  width: 2px;
}

.order-details-container::-webkit-scrollbar-track {
  background-color: #f7f0f0;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.description::-webkit-scrollbar-track {
  height: 1px;
  background-color: #ffffff;
}
::-webkit-scrollbar-thumb {
  height: 5%;
  background-color: #1c753b;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: azure;
}

::-webkit-scrollbar-track {
  background-color: #57534e;
}

.category-thumb::-webkit-scrollbar-track {
  background-color: #ffff;
  width: 2px;
}

.category-thumb::-webkit-scrollbar-thumb {
  background-color: #d0ced4;
}

.category-overflow::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.category-overflow::-webkit-scrollbar-thumb {
  background-color: #ffffff;
}

.wrapper {
  @apply container;
}

.title {
  position: relative;
}

.title::after {
  content: "";
  width: 40px;
  height: 3px;
  background-color: #d89409;
  position: absolute;
  left: 0;
  bottom: -2px;
}

@media (max-width: 640px) {
  .slider-banner {
    height: 13.5rem;
    /* width: 10rem; */
  }
}

.img img:hover {
  transform: scale(1.2);
  transition: 2s;
}

.productCarousel.carousel-root {
  display: flex;
  flex-direction: row-reverse;
  gap: 15px;
}
.productCarousel.carousel-root .carousel {
  width: auto;
}
.productCarousel.carousel-root .carousel.carousel-slider {
  width: 100%;
}
.productCarousel.carousel-root .carousel.carousel-slider .slider-wrapper {
  border-radius: 10px;
}
.productCarousel.carousel-root .carousel .thumbs-wrapper {
  margin: 0;
}
.productCarousel.carousel-root .carousel .thumb {
  height: 60px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}
.productCarousel.carousel-root .carousel .thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.productCarousel.carousel-root .carousel .thumb:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0;
}
.productCarousel.carousel-root .carousel .thumb.selected,
.productCarousel.carousel-root .carousel .thumb:hover {
  border: 0;
}
.productCarousel.carousel-root .carousel .thumb:hover:after,
.productCarousel.carousel-root .carousel .thumb.selected:after {
  opacity: 0.2;
}
.productCarousel .control-arrow {
  display: none;
}
@media screen and (max-width: 767px) {
  .productCarousel.carousel-root {
    flex-direction: column;
  }
  .productCarousel.carousel-root .carousel .thumb {
    border: 0;
    padding: 0;
  }
}
@media screen and (min-width: 768px) {
  .productCarousel.carousel-root .carousel .thumbs {
    transform: none !important;
    flex-direction: column;
    display: flex;
    gap: 10px;
  }
  .productCarousel.carousel-root .carousel .thumb {
    border: 0;
    padding: 0;
    margin: 0;
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: #f4891f !important;
  stroke: #f4891f !important;
  fill: #f4891f !important;
}

.carousel-root .main-slide .relative {
  @apply w-36;
  display: block;
}

.main-slide {
  @apply w-[100%] flex items-center justify-center gap-10 flex-col;
  text-align: center;
}

/* .carousel .slider-wrapper {
  width: 50px;
} */

.main-slide .image {
  @apply w-[100%] xl:w-[50%] h-[30rem] md:h-[50rem] lg:h-[35rem] xl:h-[40rem]  2xl:h-[50rem];
}

.carousel .thumb {
  @apply w-[100px]  h-[50px];
}

.carousel {
  width: 50%;
  text-align: center;
}

.carousel .slide {
  display: flex;
  /* justify-items: center; */
  align-items: center;
}

.carousel .thumbs {
  @apply w-60;
  text-align: center;
  align-items: center;
  justify-content: center;
}
/* .carousel .carousel-status {
  bottom: 0;
  left: 0;
  right: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
} */

.carousel.control-arrow {
  bottom: 0;
  left: 0;
  right: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.carousel.carousel-slider .control-arrow {
  color: #d89409;
}

.carousel .carousel-status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 10px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
  color: #069b19;
}
